<template>
  <div :class="{'vm-sidebar-close':sidebarCollapsed}" class="vm-sidebar">
    <el-scrollbar style="height: 100%" wrap-class="scrollbar">
      <div class="logo">
        <router-link to="/">
          <img v-if="!sidebarCollapsed" style="width: 200px;height: 40px" src="~@/assets/img/Frame.svg"/>
          <img v-else src="~@/assets/img/img.png"/>
        </router-link>
      </div>
      <transition>
        <el-menu
          :collapse="sidebarCollapsed"
          :default-active="$route.path"
          active-text-color="#FFFFFF"
          background-color="#222633"
          text-color="#EEEEEE">
          <sidebar-item
            v-for="menu in authMenus"
            :key="menu.code"
            :item="menu"
            :sidebar-collapsed="sidebarCollapsed"></sidebar-item>
        </el-menu>
      </transition>
    </el-scrollbar>
  </div>
</template>

<script>
  import SidebarItem from "./SidebarItem";

  export default {
    components: {SidebarItem},
    props: {
      sidebarCollapsed: Boolean,
    },
    data() {
      return {
        authMenus: [],
        menus: [
          {
            "menuName": "智慧大屏",
            "path": "/bigscreen/index",
            "code": "1",
            "icon": "weibao",
          },
          {
            "menuName": this.$l("moduleName.elevatorMap", "电梯地图"),
            "path": "/elevator/ElevatorMap",
            "code": "2",
            "icon": "fenbu",
          },
          // {
          //   "menuName": this.$l("moduleName.elevatorMap", "新电梯地图"),
          //   "path": "/elevator/ElevatorMapnew",
          //   "code": "2",
          //   "icon": "fenbu",
          // },
          {
            "menuName": "远程监控",
            "code": "3",
            "icon": "xitong",
            "children": [
              {
                "menuName": "遥监设备",
                "path": "/device/List",
                "code": "3.1",
              },
              {
                "menuName": this.$l("moduleName.fault", "故障记录"),
                "path": "/fault/list",
                "code": "3.2",
                "icon": "guzhang",
              },
              {
                "menuName": this.$l("moduleName.event", "事件记录"),
                "path": "/event/list",
                "code": "3.3",
                "icon": "guzhang",
              },
            ],
          },
          {
            "menuName": this.$l("moduleName.elevator", "电梯档案"),
            "path": "/elevator/ElevatorList",
            "code": "4",
            "icon": "dangan",
          },
          {
            "menuName": this.$l("moduleName.elevator", "客户档案"),
            "path": "/elevator-customer/page",
            "code": "5",
            "icon": "dangan",
          },
          {
            "menuName": this.$l("moduleName.elevator", "客户楼盘"),
            "path": "/elevator-using-realestate/page",
            "code": "6",
            "icon": "dangan",
          },

          {
            "menuName": "项目管理",
            "path": "/project/ProjectList",
            "code": "10",
            "children": [
              {"menuName": "维保项目",
               "path": "/project/ProjectList",
               "code": "10.1"},
            ],
          },
          {
            "menuName": "合同管理",
            "path": "/contract/list",
            "code": "11",
            "children": [
              {"menuName": "合同项目",
               "path": "/contract/list",
               "code": "11.1",
              },
            ],
          },
          {
            "menuName": "组织架构",
            "code": "6",
            "icon": "xitong",
            "children": [
              {
                "menuName": "公司管理",
                "path": "/framework/company",
                "code": "6.1",
              },
              {
                "menuName": "部门管理",
                "path": "/framework/department",
                "code": "6.2",
                "icon": "guzhang",
              },
              {
                "menuName": "班组管理",
                "path": "/framework/team",
                "code": "6.2",
                "icon": "guzhang",
              },
              {
                "menuName": "岗位管理",
                "path": "/framework/post",
                "code": "6.3",
                "icon": "guzhang",
              },
              {
                "menuName": "员工管理",
                "path": "/framework/employee",
                "code": "6.4",
                "icon": "guzhang",
              },
            ],
          },
          {
            "menuName": "维保业务",
            "code": "7",
            "icon": "xitong",
            "children": [
              {
                "menuName": "维修上报管理",
                "path": "/maintainplan/reportrepair",
                "code": "7.3",
                "icon": "guzhang",
              },
              {
                "menuName": "维修工单管理",
                "path": "/maintainplan/repairorder",
                "code": "7.4",
                "icon": "guzhang",
              },
              {
                "menuName": "保养计划管理",
                "path": "/maintainplan/maintainplan",
                "code": "7.2",
                "icon": "guzhang",
              },
              {
                "menuName": "保养工单管理",
                "path": "/maintainplan/maintainorder",
                "code": "fault:query",
                "icon": "guzhang",
              },
              {
                "menuName": "工单生成配置",
                "path": "/maintainplan/maintenanceOrderConfig",
                "code": "fault:query",
                "icon": "guzhang",
              },

              {
                "menuName": "工单超时配置",
                "path": "/maintainplan/notice",
                "code": "7.5",
                "icon": "guzhang",
              },
              // ,{
              //   "menuName": "按需维保规则配置",
              //   "path": "/maintainplan/config",
              //   "code": "7.5",
              //   "icon": "guzhang",
              // },
            ],
          },
          {
            "menuName": "用户权限管理",
            "code": "99",
            "icon": "xitong",
            "children": [
              {
                "menuName": "用户管理",
                "path": "/user/UserList",
                "code": "99.1",
              },
              {
                "menuName": "角色管理",
                "path": "/role/RoleList",
                "code": "99.2",
              },
              {
                "menuName": "登录日志",
                "path": "/log/logs",
                "code": "99.3",
              },
            ],
          },
          {
            "menuName": "基础数据管理",
            "code": "999",
            "icon": "xitong",
            "children": [
              {
                "menuName": "省市区",
                "path": "/basic/district",
                "code": "999.1",
              },
              {
                "menuName": "权限菜单",
                "path": "/basic/permissions",
                "code": "999.2",
              },
              {
                "menuName": "电梯制造商/品牌/型号",
                "path": "/basic/modal",
                "code": "999.3",
              },
              {
                "menuName": "电梯故障字典",
                "path": "/basic/fault",
                "code": "999.4",
                "icon": "guzhang",
              },
              {
                "menuName": "电梯保养字典",
                "path": "/basic/Maintenance",
                "code": "999.6",
                "icon": "guzhang",
              },
              {
                "menuName": "电梯配件管理",
                "path": "/basic/accessory",
                "code": "999.7",
                "icon": "guzhang",
              },
              {
                "menuName": "电梯故障现象管理",
                "path": "/basic/appearance",
                "code": "999.7",
                "icon": "guzhang",
              },

            ],
          },
        ],
      };
    },
    mounted() {
      if (JSON.parse(localStorage.getItem("authorities"))) {
        this.authMenus = JSON.parse(localStorage.getItem("authorities"));
        console.log(this.authMenus,1284);
      } else {
        // this.filterMenu(this.menus, this.authMenus);
        this.authMenus = this.menus;
      }
    },
    methods: {
      // filterMenu(menus, authMenus) {
      //   menus.forEach(item => {
      //     if (item.children) {
      //       let itemTemp = JSON.parse(JSON.stringify(item));
      //       if (itemTemp.children) {
      //         itemTemp.children = [];
      //       }
      //       authMenus.push(itemTemp);
      //       if (item.children) {
      //         this.filterMenu(item.children, itemTemp.children);
      //       }
      //     }
      //   });
      // },
    },
  };
</script>

<style lang="scss">
  @import "../../style/variables";

  .vm-sidebar {
    transition: width 0.25s ease-in-out;
    width: $sidebar-width;
    background-color: #222633;
    z-index: 10;

    .el-menu {
      border: none;
    }

    .icon-lg {
      font-size: 22px;
    }

    .iconfont {
      color: #ddd;
    }

    .el-menu-item {
      background-color: #000c17 !important;

      &:hover {
        background-color: #21252c !important;
      }
    }

    .is-active {
      background-color: $color-primary !important;

      &:hover {
        background-color: $color-primary !important;
      }
    }

    .logo {
      padding: 10px;
      text-align: center;
      overflow: hidden;
      background-color: #243645;

      img {
        height: 29px;
        width: auto;
      }

      a {
        &:hover {
          color: #0088CC;
          text-decoration: none;
        }
      }
    }

    .scrollbar {
      height: 100vh;
      overflow-x: hidden;
    }

    .el-menu--collapse .el-menu-item .el-submenu__icon-arrow,
    .el-menu--collapse .el-submenu .el-submenu__title .el-submenu__icon-arrow,
    .el-menu--collapse .el-menu-item span,
    .el-menu--collapse .el-submenu .el-submenu__title span {
      display: none;
    }
  }

  .vm-sidebar-close {
    width: $sidebar-width-close;
  }
</style>
